import React, {Component} from "react";
import {Link} from 'react-router-dom';
import "./Contact.css";
import Swal from 'sweetalert2'
import { hover } from "@testing-library/user-event/dist/hover";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {message: '',
                    name: '',
                    phone:'',
                    email: '',
                  checkedRodo: false,
                  checkedSubmit: false, 
                 };
  }
  handleChangeRodo() {
    this.setState({
        checkedRodo: !this.state.checkedRodo     
    })
  }
  handleSubmit(event) {
    this.setState({
      checkedSubmit: !this.state.checkedSubmit    
  })
  }

  nameChange = (event) => {
    this.setState({name: event.target.value})
  }
 
  emailChange = (event) => {
    this.setState({email: event.target.value})
  }

  phoneChange = (event) => {
    this.setState({phone: event.target.value})
  }
 
  messageChange = (event) => {
    this.setState({message: event.target.value})
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const templateId = 'template_xtis6fc';

    this.sendFeedback(templateId, {
      message: this.state.message, 
      name: this.state.name, 
      email: this.state.email,
      phone: this.state.phone
     }
)

}

sendFeedback = (templateId, variables) => {
  window.emailjs.send(
    'service_d39sd46', templateId,
    variables
    ).then(res => {
      console.log("succes") ;
                Swal.fire({
                    title: 'Wiadomość została wysłana',
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                    confirmButtonColor:"#C0C0C0",
                    icon: 'success'
                }
                ).then(res => {
                
                  setTimeout(() => {
                        window.location.reload();
                      }, 1500); console.log('wyslano')
                })
              }  
                    
    )
    .catch(err => {
      console.error('Email Error:', err)
    })
  
}

    render() {
      return (
          <div className='contact-container'>
            <div className='div-contact1'>
                <h1>Dane kontaktowe</h1>
                <hr></hr>
                <h2 style={{marginTop:"7%"}}> inż. Adam Stroczkowski </h2>
                   <h3 style={{color:"#06da62"}}> tel. <a href="tel:506402722">+48 506 402 722</a></h3>
                    <h3 style={{color:"#06da62"}}>e-mail: <a href="mailto: rzeczoznawca@rzeczoznawca-bialystok.pl">rzeczoznawca@rzeczoznawca-bialystok.pl</a></h3>    
                   <div className='contact-image'>
                   <img src="./shutterstock_15124303.jpg"></img>
                   </div>
            </div>
            
            <div className='div-contact2'>
                <h1>Formularz kontaktowy</h1>
                <hr></hr>
                <div class="screen-body-item">
                      <form class="app-form" onSubmit={this.handleSubmit}>
                        <div class="app-form-group">
                          <input class="app-form-control" type="text" onChange={this.nameChange} placeholder="IMIĘ" required/>
                        </div>
                        <div class="app-form-group">
                          <input class="app-form-control" type="email" onChange={this.emailChange} placeholder="EMAIL" required/>
                        </div>
                        <div class="app-form-group">
                          <input class="app-form-control" type="tel" onChange={this.phoneChange} placeholder="TELEFON"/>
                        </div>
                        <div class="app-form-group message">
                          <textarea class="app-form-control" onChange={this.messageChange} placeholder="WIADOMOŚĆ" required rows="7"/>
                        </div>
                      

                        <div className='div-contact-form-checkbox'>
                               
                                <button class="app-form-button" type="submit"  checked={ this.state.checkedSubmit }  onChange={ this.handleSubmit } >WYŚLIJ</button>
                               
                        </div> 
                        
                        
          </form>
        </div>
  
            </div>
          </div>
      );
    }
  }
   
export default Contact;