import './Navbar.css';
import {Link, NavLink} from 'react-router-dom';


function Navbar() {
    return (
        <div className='navbar-div'>
            <div className='navbar-top'>
            <div className='navbar-contact'>
                <img id="contact-images"  src="/phone1.png"  style={{marginTop:"2%"}}/>
                            <div className='div-navbar-inline'>
                            <h4 > Zadzwoń: <a href="tel:506402722">+48 506-402-722</a></h4>
                            </div>
                            </div>
               
                <div className='navbar-contact-left'>
                <img id="contact-images"  src="/plane1.png" style={{marginTop:"2%"}}/>
                            <div className='div-navbar-inline'>
                            <h4 > Napisz: <a href="mailto: rzeczoznawca@rzeczoznawca-bialystok.pl">rzeczoznawca@rzeczoznawca-bialystok.pl</a></h4>
                            </div>
                            </div>
            </div>
            <div className='navbar-pasek'>
                <div className='navbar-name'>
                    <h1>inż. Adam Stroczkowski</h1>
                </div>
                    <div className='navbar'>
                    <div className='topnav'>
                            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : 'inactive')} onChange={() => window.location.reload(false)}>Strona główna</NavLink>
                            <NavLink to="/omnie" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>O mnie</NavLink>
                            <NavLink to="/uslugi" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Usługi</NavLink>
                            <NavLink to="/kontakt" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Kontakt</NavLink>
                            </div>
                    </div>
            </div>
        </div>
    )
}

export default Navbar;