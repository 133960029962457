import React, {Component} from "react";
import {Link} from 'react-router-dom';
import "./Services.css";

class Services extends Component {
    render() {
      return (
          <div className='services-container'>
            <div className='div-services-top'>
            <h1>Usługi</h1>
            <hr></hr>
        
            </div>
           
           <div className='div-services-table'>
           <table>
              <tr>
                <th></th>
              </tr>
              <tr>
                <td>wycena / określenie wartości pojazdów w tym samochodów osobowych i ciężarowych (np. na potrzeby Urzędów Celnych, Banków, Leasingów, Osób Fizycznych, Przedsiębiorstw, itd.)</td>
              </tr>
              <tr>
                <td>wycena / określenie wartości pojazdów w tym samochodów uszkodzonych</td>
              </tr>
              <tr>
                <td>wycena kosztów naprawy pojazdów i szkód komunikacyjnych</td>
              </tr>
              <tr>
                <td>wycena / określenie wartości wszelkiego rodzaju ruchomości, maszyn i urządzeń (maszyny przemysłowe, budowlane, drogowe, rolnicze, środki trwałe, urządzenia RTV i AGD, sprzęt komputerowy, itd.)</td>
            </tr>
              </table>

           </div>
          </div>
      );
    }
  }
   
export default Services;