import './Footer.css';
import {Link} from 'react-router-dom';
import '../App.css';

function Footer() {
    return (
        <div className='footer'>

            <div className='footer-cointainer'>
                <div id="first-div" className='div-footer' style={{verticalAlign:"top"}}>
                    <h1>inż. Adam Stroczkowski</h1>
                  
                    <span className="footer-span" style={{color:"rgb(205, 205, 205)",verticalAlign:"bottom"}}>
                    <h3 >Rzeczoznawca SIMP nr 10032/18</h3>
                    <h3 className="footer-span">Wycena maszyn, urządzeń i pojazdów</h3>
                    <h3 className="footer-span">Białystok, Podlaskie</h3></span>
                </div>
                <div id="second-div" className='div-footer' style={{verticalAlign:"super"}}>
                <br></br>
                <br></br>
                
                <div className='footer-img-div' >
                            <img className='footer-img'  src="/phone1.png" />
                            </div>
                            <div className='div-footer-inline'>
                            <h3 ><a href="tel:506402722">+48 506-402-722</a></h3>
                            </div>
                           <br></br>
                           <div className='footer-img-div'>
                            <img className='footer-img' src="/plane1.png" />
                            </div>
                            <div className='div-footer-inline'>
                            <h3 ><a href="mailto: rzeczoznawca@rzeczoznawca-bialystok.pl">rzeczoznawca@rzeczoznawca-bialystok.pl</a></h3>
                            </div>
                </div>
                <div className='div-footer' >
                    <img className='div-footer-logo' src="/LOGO SIMP.png"/>
                </div>
            </div>
            <div className='copy'>
               <h4 style={{marginLeft:"16%"}}>Realizacja <a style={{textDecoration: "none"}} target="_blank" href="https://cloverit.pl">CloverIT</a>&copy; 2022 </h4>
            </div>
            
        </div>
        )
    }
    
    export default Footer;