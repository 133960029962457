import React, {Component} from "react";
import './Home.css';
import {Link} from 'react-router-dom';

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const fadeImages = [
'ciezarowki_u.jpg',
'wypadek_u.jpg',
'koparka_traktor_u.jpg'
];

const fadeProperties = {
  duration: 3000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  autoplay:true,
};

const Home = () => {
  return (
    <div className="slide-container">
     <Fade {...fadeProperties}>   
      <div className="each-slide">
            <div className='span-div'>
            <img src={fadeImages[0]} style={{marginRight:"15%"}}></img>
            <div  className='div-text'> <p id="p-div-big" style={{fontWeight:"900",  marginTop:"-5%",  marginBottom:"1%"}}>Sprowadziłeś samochód z zagranicy? </p>
                    <p id="p-div" >Twoje auto jest uszkodzone i w Urzędzie Celnym nie są w stanie określić aktualnej wartość pojazdu? </p>
                    <p style={{fontWeight:"900"}}>Świetnie trafiłeś!</p>
                    <p>Potrzebujesz profesjonalnej opinii / wyceny Rzeczoznawcy, który rozwiąże Twój problem.</p>
                    <Link to="/kontakt">
                      <button className='button'>Zadzwoń/ Napisz</button>
                    </Link>
                    </div>
            </div>
          </div>
          <div className="each-slide">
            <div className='span-div'>
              <img src={fadeImages[1]}></img>
              <div className='div-text'><p id="p-div-big" style={{fontWeight:"900", marginTop:"-5%", marginBottom:"1%"}}>Miałeś wypadek, stłuczkę lub szkodę parkingową?</p>
                    <p id="p-div" >Nie zgadzasz się z wyceną kosztów naprawy Twojego samochodu zaproponowaną przez Towarzystwo Ubezpieczeniowe?</p>
                    <p style={{fontWeight:"900"}}>Świetnie trafiłeś!</p>
                    <p>Potrzebujesz opinię i kalkulację naprawy wykonaną przez niezależnego Rzeczoznawcę.</p>
                    <Link to="/kontakt">
                      <button className='button'>Zadzwoń/ Napisz</button>
                    </Link>
                    </div>
            </div>
          </div>
          <div className="each-slide">
            <div className='span-div' >
            <img src={fadeImages[2]} style={{marginRight:"20%"}}></img>
              <div  className='div-text'><p id="p-div-big" style={{fontWeight:"900",  marginTop:"-5%", marginBottom:"1%"}}>Potrzebujesz wycenić jakąś maszynę?</p>
              <p> Budowlaną, przemysłową, rolniczą, albo sprzęt komputerowy, urządzenie RTV / AGD?</p>
              <p style={{fontWeight:"900"}}>Świetnie trafiłeś!</p>   
              <Link to="/kontakt">
                      <button className='button'>Zadzwoń/ Napisz</button>
                    </Link>   
              </div>
            </div>
          </div>
      
        </Fade>


        <div className='home-kafle'>
        <h1 style={{fontWeight:"900"}}>Co mnie wyróżnia?</h1>
         
          <div className='kafle'>
          <img src="opinion.png"></img>
              <h2 style={{fontWeight:"900"}}>Zadowolenie klienta</h2>
              <hr></hr>
              <h3>Jakość oferowanych usług, przekłada się na pozytywne opinie od Klientów. <a style={{color:"#06da62"}} href="https://goo.gl/maps/wzUQjP8u7wHe2bJj7">Sprawdź sam.</a></h3>
          </div>
          <div className='kafle'>
              <img src="time-left.png"></img>
              <h2>Terminowość</h2>
              <hr></hr>
              <h3>Termin realizacji jest z góry ustalany z Klientem. W razie potrzeby, pilne zlecenia są realizowane expresem.</h3>
          </div>
          <div className='kafle'>
          <img src="like.png"></img>
              <h2>Doświadczenie</h2>
              <hr></hr>
              <h3>Posiadam wieloletnią praktykę oraz certyfikaty potwierdzające umiejętności oraz wiedzę.</h3>
          </div></div>
    </div>
  )
}
export default Home;