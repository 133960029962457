import React, {Component} from "react";
import {Link} from 'react-router-dom';
import "./About.css";

class About extends Component {
    render() {
      return (
          <div className='about-container'>
            <div className='div-about-top'>
            <h1>O mnie</h1>
            <hr></hr>
        
            </div>
           <div className='div-about-napis2'>
            <p>Nazywam się Adam Stroczkowski. Jestem certyfikowanym Rzeczoznawcą SIMP wpisanym na listę Rzeczoznawców pod numerem 10032/18, a także wieloletnim członkiem Stowarzyszenia Inżynierów i Techników Mechaników Polskich.</p>
                  <br></br>
                  <br></br>
                  <p>Specjalizacja:</p>
                  <p id="p-about"><span style={{color:"#06da62"}}>830</span> – Wycena maszyn, urządzeń i pojazdów</p>
                  <p id="p-about"><span style={{color:"#06da62"}}>314</span> – Maszyny dla przemysłu spożywczego</p>
            
           </div>
           <div id="about-div" className='div-about-napis2'>   
            <img src="./Truck (7).jpg"></img>
           </div>

           <div className='div-about-top'>
            <h1>Certyfikaty</h1>
            <hr></hr>
            <div className='div-certyfikaty'>
            <Link to="/./legitymacja.jpg"  target="_blank" rel="noopener noreferrer">
                    <img src="./legitymacja.jpg"></img></Link>
            <Link to="/./Certyfikat SIMP-1.jpg"  target="_blank" rel="noopener noreferrer">
                    <img src="./Certyfikat SIMP-1.jpg"></img></Link>
            <Link to="/./Certufikat 1 SIMP-1.jpg" target="_blank" rel="noopener noreferrer"><img src="./Certufikat 1 SIMP-1.jpg"></img> </Link>
            
            <Link to="/.\dyplom Audatex.pdf" target="_blank" rel="noopener noreferrer"><img src=".\dyplom Audatexwebp.webp"></img> </Link>
            </div>
            </div>
          </div>
      );
    }
  }
   
export default About;