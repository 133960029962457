import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Services from './components/pages/Services';
import './App.css';

function App() {
  return (
    <BrowserRouter>
    <Navbar sticky="top"/>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/omnie" element={<About />}/>
      <Route path="/kontakt" element={<Contact />}/>
      <Route path="/uslugi" element={<Services />}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;


